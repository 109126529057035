
import {defineComponent, ref} from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "kt-notify",
  emits: [
      'added_exception'
  ],
  computed: {
    getRightWord() {
      return (this.affiliate_id == null) ? 'affiliate' : 'publisher';
    }
  },
  props: {
    offer_id: {
      type: null || Number,
      required: true,
      default: null,
    },
    affiliate_id: {
      type: null || String,
      required: false,
      default: null,
    },
    affiliates: {
      type: null || Array,
      required: false,
      default: null,
    },
  },
  methods: {
    handleSelectedAffiliate() {
      this.selected = '';
      ApiService.query('publishers', { responseType: 'json', params: { filters: { affiliateId: this.affId }}}).then((res)=>{
        this.publishers = res.data.data;
      });
    },
    saveException() {
      ApiService.post('/offer/'+this.offer_id.toString()+'/payout', {
        publisher_id: this.selected != '' ? this.selected : null,
        affiliate_id: this.affId,
        amount: this.amount,
      }).then((res) => {
        this.emitChange();
      });
    },
    abortOperation() {
      this.amount = '';
      this.selected = '';
      this.affId = this.affiliate_id;
      this.editMode = false;
    },
    enableControl() {
      if(this.affId != null) {
        this.handleSelectedAffiliate();
      }
      this.editMode = true;
    },
    emitChange() {
      this.$emit('added_exception');
      this.editMode = false;
    }
  },
  mounted() {
    this.affId = this.affiliate_id;
  },
  data() {
    return {
      affId: '',
      selected: '',
      amount: '',
      publishers: [],
      oldValue: '',
      content: this.modelValue,
      editMode: false,
    };
  },
  components: {},
});
