
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "ll-attachment-list",
  emits: ["added_file"],
  props: {
    offer_id: {
      type: Number,
      required: true,
      default: null,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: true,
      default: null,
    },
    title: {
      type: String,
      required: true,
      default: null,
    },
    subtitle: {
      type: String,
      required: false,
      default: "Downloads",
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    deleteAttachment(id) {
      ApiService.delete("attachments", id).then((res) => {
        this.$emit("added_file", res.data);
      });
    },
    uploadFile(data) {
      let formData = new FormData();
      formData.append("file", data.file);
      formData.append("type", this.type);
      ApiService.post(
        "offer/" + this.offer_id.toString() + "/attachment",
        formData
      )
        .then((res) => {
          this.$emit("added_file", res.data);
        })
        .catch(this.catchErrors);
    },
    catchErrors(error) {
      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
  },
  mounted() {
    this.editMode = true;
  },
  data() {
    return {
      editMode: false,
    };
  },
  components: {},
});
