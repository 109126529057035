
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "ll-attachment-display",
  emits: ["added_file"],
  props: {
    offer_id: {
      type: Number,
      required: true,
      default: null,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: true,
      default: null,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    getImagePath() {
      if (this.items.length > 0) {
        return (this.items[this.items.length - 1] as any).path;
      } else {
        return "/media/logos/logo-2.svg";
      }
    },
  },
  methods: {
    deleteAttachment(id) {
      ApiService.delete("attachments", id).then((res) => {
        this.$emit("added_file", res.data);
      });
    },
    uploadFile(data) {
      let formData = new FormData();
      formData.append("file", data.file);
      formData.append("type", this.type);
      ApiService.post(
        "offer/" + this.offer_id.toString() + "/attachment",
        formData
      )
        .then((res) => {
          this.$emit("added_file", res.data);
        })
        .catch(data.onError);
    },
  },
  mounted() {
    this.editMode = true;
  },
  data() {
    return {
      editMode: false,
    };
  },
  setup() {
    const store = useStore();

    return { store };
  },
  components: {},
});
