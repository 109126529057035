import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_ctx.store.getters.currentUser.moderator)
    ? (_openBlock(), _createBlock(_component_el_upload, {
        key: 0,
        "show-file-list": false,
        multiple: false,
        "http-request": _ctx.uploadFile,
        action: "undefined"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            class: "mw-100px mw-lg-150px",
            src: _ctx.getImagePath,
            alt: "image"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["http-request"]))
    : (_openBlock(), _createElementBlock("img", {
        key: 1,
        class: "mw-100px mw-lg-150px",
        src: _ctx.getImagePath,
        alt: "image"
      }, null, 8, _hoisted_2))
}