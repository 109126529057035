
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "kt-notify",
  emits: ["added_exception"],
  props: {
    offer_id: {
      type: null || Number,
      required: true,
    },
    payout_id: {
      type: null || Number,
      required: true,
    },
    affiliate_id: {
      type: null || Number,
      required: true,
      default: null,
    },
    publisher_id: {
      type: Number,
      required: false,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
    modelValue: {
      type: String,
      default: "",
      required: true,
    },
  },
  methods: {
    handleBlur() {
      if (this.content === this.oldValue) {
        this.editMode = false;
      }
    },
    handleFocus() {
      (this.$refs.myInput as any).select();
    },
    handleKeyPress(e) {
      if (e.key == "Escape") this.handleCancel();
      if (e.key == "Enter") this.handleSave();
    },
    toggleEditMode() {
      if (this.editable) {
        this.editMode = true;
        this.oldValue = this.content;
        setTimeout(() => {
          (this.$refs.myInput as any).select();
          (this.$refs.myInput as any).focus();
        }, 50);
      }
    },
    handleSave() {
      ApiService.post("/offer/" + this.offer_id.toString() + "/payout", {
        publisher_id: this.publisher_id ? this.publisher_id : null,
        affiliate_id: this.affiliate_id,
        amount: this.content,
      });
      this.emitChange();
    },
    handleDelete(id) {
      ApiService.delete("/offer/" + this.offer_id.toString() + "/payout", id);
      this.emitChange();
    },
    handleCancel() {
      this.editMode = false;
      this.content = this.oldValue;
    },
    emitChange() {
      this.$emit("added_exception");
      this.editMode = false;
    },
  },
  data() {
    return {
      oldValue: "",
      content: this.modelValue,
      editMode: false,
    };
  },
  components: {},
});
